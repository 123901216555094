import React, { useState } from "react"
import ReactDOMServer from 'react-dom/server'
import ReactDOM from 'react-dom'
import Box from '@material-ui/core/Box'
import rows from '../../data/all_data_obj_min'
import Meats from "./icons/meats"
import Oil from "./icons/oil"
import Vegetable from "./icons/vegetable"
import Grains from "./icons/grains"
import Power from "./icons/power"
import Sodium from "./icons/sodium"
import Button from '@material-ui/core/Button'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

var Buffer = require('buffer/').Buffer 

function encodeSvgtoImg (svgEle, alt, _class) {
    const svgRender = ReactDOMServer.renderToStaticMarkup(svgEle)
    const svgImg = Buffer.from(svgRender, 'utf8').toString('base64')
    
    const srcimg = 'data:image/svg+xml;base64,' + escape(svgImg);
    return (
        <>
            <img src={srcimg} alt={alt} title={alt} class={_class} />
        </>
    )
}

const Alimentos = (props) => {
    //console.log(props.data)
    const [multplier, setMultplier] = useState(1)

    const handleMultplier = (event, newMultplier) => {
        setMultplier(newMultplier)
    };

    const roundOne = (n, d) => Math.round(n * Math.pow(10, d)) / Math.pow(10, d)

    const almFiltered = rows.alimentos.filter(val => val["1"] === props.data.id)[0]
    //console.log(almFiltered)

    const classes = {
        box: "w-1/3 mx-0 my-0 flex flex-col justify-between",
        mainDiv: "p-0 flex flex-col sm:flex-row justify-start items-center",
        iconDiv: "p-2 xl:p-4 mt-2 sm:mt-0 shadow-inner rounded-full sm:rounded-l-none",
        svgBox: "w-7 h-7 2xl:w-12 2xl:h-12",// fill-current text-white",
        h5Nutri: "mx-auto text-center font-bold text-white text-md lg:text-lg 2xl:text-xl",
        h6Value: "w-full p-3 text-center font-bold text-white text-lg 2xl:text-4xl",
        h6Small: "font-light text-sm 2xl:text-xl",
    }

    return (
        <>

        <Box className={"my-4 w-full flex flex-row justify-start content-center items-center"}>
           
            <h7 className={"font-light text-xs uppercase tracking-widest leading-loose mr-3"}>
                Quantidade:
            </h7>

            <ToggleButtonGroup
                size="small"
                value={multplier}
                exclusive
                onChange={handleMultplier}
                aria-label="multiplicador da quantidade"
                size="medium"
            >
                <ToggleButton value={1} classes={{root:'text-gray-900'}} aria-label="uma grama">
                    1
                    <small className={"lowercase"}>
                        g
                    </small>
                </ToggleButton>
                <ToggleButton value={10} aria-label="dez gramas">
                    10
                    <small className={"lowercase"}>
                        g
                    </small>
                </ToggleButton>
                <ToggleButton value={25} aria-label="vinte e cinco gramas">
                    25
                    <small className={"lowercase"}>
                        g
                    </small>
                </ToggleButton>
                <ToggleButton value={100} aria-label="cem gramas">
                    100
                    <small className={"lowercase"}>
                        g
                    </small>
                </ToggleButton>
            </ToggleButtonGroup>

        </Box>

        <Box className={"my-4 w-full flex flex-col justify-start content-center items-center"}>

            <Box className={"w-full flex flex-row"}>

                <Box className={`${classes.box} bg-blue-800`}>

                    <div className={`${classes.mainDiv}`}>
                        <div className={`${classes.iconDiv} bg-blue-600`}>

                            {
                                encodeSvgtoImg(<Power fill={"#fff"} />,
                                               `${props.data.name} tem ${roundOne(almFiltered["5"], 5)} de calorias por gramas`,
                                               classes.svgBox)
                            }

                        </div>
                        
                        <h5 className={`${classes.h5Nutri}`}>
                            Calorias
                        </h5>

                    </div>

                    <h6 className={`${classes.h6Value} bg-blue-900`}>
                        
                        {roundOne(almFiltered["5"]*multplier, 5)}
                        <small className={`${classes.h6Small}`}>
                            kcal
                        </small>

                    </h6>

                </Box>

                <Box className={`${classes.box} bg-yellow-800`}>

                    <div className={`${classes.mainDiv}`}>
                        <div className={`${classes.iconDiv} bg-yellow-600`}>

                            {
                                encodeSvgtoImg(<Grains fill={"#fff"} />,
                                                `${props.data.name} tem ${roundOne(almFiltered["8"], 5)} de carboidratos por gramas`,
                                                classes.svgBox)
                            }

                        </div>
                        
                        <h5 className={`${classes.h5Nutri}`}>
                            Carboidratos
                        </h5>

                    </div>

                    <h6 className={`${classes.h6Value} bg-yellow-900`}>
                        
                        {roundOne(almFiltered["8"]*multplier,5)}
                        <small className={`${classes.h6Small}`}>
                            g
                        </small>

                    </h6>

                </Box>

                <Box className={`${classes.box} bg-red-800`}>

                    <div className={`${classes.mainDiv}`}>
                        <div className={`${classes.iconDiv} bg-red-600`}>


                            {
                                encodeSvgtoImg(<Meats fill={"#fff"} />,
                                                `${props.data.name} tem ${roundOne(almFiltered["9"], 5)} de proteínas por gramas`,
                                                classes.svgBox)
                            }

                        </div>
                        
                        <h5 className={`${classes.h5Nutri}`}>
                            Proteínas
                        </h5>

                    </div>

                    <h6 className={`${classes.h6Value} bg-red-900`}>
                        
                        {roundOne(almFiltered["9"]*multplier, 5)}
                        <small className={`${classes.h6Small}`}>
                            g
                        </small>

                    </h6>

                </Box>

            </Box>

            <Box className={"w-full flex flex-row"}>

                <Box className={`${classes.box} bg-green-800`}>

                    <div className={`${classes.mainDiv}`}>
                        <div className={`${classes.iconDiv} bg-green-600`}>

                            {
                                encodeSvgtoImg(<Oil fill={"#fff"} />,
                                                `${props.data.name} tem ${roundOne(almFiltered["10"], 5)} de gorduras por gramas`,
                                                classes.svgBox)
                            }

                        </div>
                        
                        <h5 className={`${classes.h5Nutri}`}>
                            Gorduras
                        </h5>

                    </div>

                    <h6 className={`${classes.h6Value} bg-green-900`}>
                        
                        {roundOne(almFiltered["10"]*multplier, 5)}
                        <small className={`${classes.h6Small}`}>
                            g
                        </small>

                    </h6>

                </Box>

                <Box className={`${classes.box} bg-pink-800`}>

                    <div className={`${classes.mainDiv}`}>
                        <div className={`${classes.iconDiv} bg-pink-600`}>


                            {
                                encodeSvgtoImg(<Sodium fill={"#fff"} />,
                                                `${props.data.name} tem ${roundOne(almFiltered["17"], 5)} de sódio por gramas`,
                                                classes.svgBox)
                            }

                        </div>
                        
                        <h5 className={`${classes.h5Nutri}`}>
                            Sódio
                        </h5>

                    </div>

                    <h6 className={`${classes.h6Value} bg-pink-900`}>
                        
                        {roundOne(almFiltered["21"]*multplier, 5)}
                        <small className={`${classes.h6Small}`}>
                            mg
                        </small>

                    </h6>

                </Box>

                <Box className={`${classes.box} bg-gray-800`}>

                    <div className={`${classes.mainDiv}`}>
                        <div className={`${classes.iconDiv} bg-gray-600`}>


                            {
                                encodeSvgtoImg(<Vegetable fill={"#fff"} />,
                                                `${props.data.name} tem ${roundOne(almFiltered["11"], 5)} de fibra alimentar por gramas`,
                                                classes.svgBox)
                            }

                        </div>
                        
                        <h5 className={`${classes.h5Nutri}`}>
                            Fibra Alimentar
                        </h5>

                    </div>

                    <h6 className={`${classes.h6Value} bg-gray-900`}>
                        
                        {roundOne(almFiltered["11"]*multplier, 5)}
                        <small className={`${classes.h6Small}`}>
                            g
                        </small>

                    </h6>

                </Box>

            </Box>

        </Box>

        </>
    )
}
    
export default Alimentos;