import React from "react"
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../components/theme'
import '../css/style.css'
import Nav from '../components/_nav'
import PageInfoAlimento from '../components/page_infoalimento_sidebar'
import Footer from '../components/footer'
import SEO from '../components/seo'
import InformacoesData from '../data/informacoes.json'
import DataAlms from '../../data/all_data_obj_min'
import { graphql } from 'gatsby'

const roundOne = (n, d) => Math.round(n * Math.pow(10, d)) / Math.pow(10, d)
String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

const NutrientesPages = props => {

    const { pageContext } = props

    const imgSrc = props.data.allImageSharp.edges[0].node.gatsbyImageData.images.fallback.src

    const dataAlm = DataAlms.alimentos.filter(val => {
        if (val["1"] === pageContext.id) {
            return val
        }
    })[0]

    const dnutrinfo = {

        "kcal": roundOne(dataAlm["5"], 5),
        "carbo": roundOne(dataAlm["8"], 5),
        "prot": roundOne(dataAlm["9"], 5),
        "fat": roundOne(dataAlm["10"], 5),
        "sal": roundOne(dataAlm["21"], 5),
        "acucar": roundOne(dataAlm["40"], 5),
        "fibra": roundOne(dataAlm["11"], 5),

    }

    props.location.host = "infonutrientes.com.br"
    
    return (
        <ThemeProvider theme={theme}>
            <>
                <SEO
                    title={"Informações nutricionais de: " + pageContext.name}
                    description={"Informações nutricionais de: " + pageContext.name}
                    keywords={"Informações nutricionais de: " + pageContext.name}
                    canonical={`https://${props.location.host}${pageContext._path}`}
                    base_url={`https://${props.location.host}`}
                    post_date={"2021-03-15"}
                    post_cat={pageContext.cat}
                    post_cat_name={pageContext.cat === "miscelanea" ? "Miscelânea" : pageContext.cat.capitalize()}
                    post_slug={pageContext.slug}
                    post_slug_name={"Informações nutricionais de: " + pageContext.name}
                    post_descp_kps={"Informações nutricionais de: " + pageContext.name}
                    nutrinfo={dnutrinfo}
                    location={props.location}
                    image={imgSrc}
                />
            </>

            <>
                <Nav />
            </>

            <main>
                <PageInfoAlimento info={pageContext} {... props} />
            </main>

            <>
                <Footer />
            </>



        </ThemeProvider>
    )
}

export default NutrientesPages;

export const pageQuery = graphql`
    query ($img: String) {
        allImageSharp(filter: {original: {src: {regex: $img}}}) {
            edges {
                node {
                    gatsbyImageData
                }
            }
        }
    }
`;