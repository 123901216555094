import React, { forwardRef } from 'react';
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    borderLeft: {
        borderLeft: '1px solid black',
        borderBottom: '1px dashed black',
        boxSizing: 'border-box'
    },
    borderRight: {
        borderRight: '1px solid black',
        borderBottom: '1px dashed black',
        boxSizing: 'border-box'
    },
    borderNone: {
        border: 'none'
    },
    borderDashed: {
        borderBottom: '1px dashed black',
        boxSizing: 'border-box'
    },
    borderSolid: {
        borderBottom: '1px solid black',
        boxSizing: 'border-box'
    },
    borderTopRight: {
        borderRight: '1px solid black',
        borderTop: '1px solid black',
        borderBottom: 'none',
        borderLeft: 'none',
        boxSizing: 'border-box'

    },
    borderTopLeft: {
        borderRight: 'none',
        borderTop: '1px solid black',
        borderBottom: 'none',
        borderLeft: '1px solid black',
        boxSizing: 'border-box'

    },
    borderRightLeft: {
        borderRight: '1px solid black',
        borderLeft: '1px solid black',
        boxSizing: 'border-box'

    },
}))

const roundOne = (n, d) => Math.round(n * Math.pow(10, d)) / Math.pow(10, d)

const NutriFacts = forwardRef((props, ref) => {
    
    const classes = useStyles()

    const macroIDs = [4, 5, 6, 7]
    const saisIDs = [15, 16, 17, 18, 19, 20, 21, 22, 23];
    //const vitaIDs = [25, 26, 27, 28, 29, 30, 31, 32, 33, 34];
    //const mIDs = [[4, 25, 15], [5, 26, 16], [6, 27, 17], [7, 28, 18]]
    //const svIDs = [[29, 19], [30, 20], [31, 21], [32, 22], [33, 23]]
    const mIDs = [[8, 29, 19], [9, 30, 20], [10, 31, 21], [11, 32, 22]]
    const svIDs = [[33, 23], [34, 24], [35, 25], [36, 26], [37, 27]]

    const compsName =         
    {
        "5": {"componente":"Energia", "unidade": "kcal"},
        "8": {"componente":"Carboidrato", "unidade": "g"},
        "9": {"componente":"Proteína", "unidade": "g"},
        "10": {"componente":"Lipídio", "unidade": "g"},
        "11": {"componente":"Fibra alimentar", "unidade": "g"},
        "19": {"componente":"Cálcio", "unidade": "mg"},
        "20": {"componente":"Ferro", "unidade": "mg"},
        "21": {"componente":"Sódio", "unidade": "mg"},
        "22": {"componente":"Magnésio", "unidade": "mg"},
        "23": {"componente":"Fósforo", "unidade": "mg"},
        "24": {"componente":"Potássio", "unidade": "mg"},
        "25": {"componente":"Zinco", "unidade": "mg"},
        "26": {"componente":"Cobre", "unidade": "mg"},
        "27": {"componente":"Selênio", "unidade": "mcg"},
        "29": {"componente":"Vitamina A", "unidade": "mcg"},
        "30": {"componente":"Vitamina D", "unidade": "mcg"},
        "31": {"componente":"Vitamina E", "unidade": "mg"},
        "32": {"componente":"Vitamina B1", "unidade": "mg"},
        "33": {"componente":"Vitamina B2", "unidade": "mg"},
        "34": {"componente":"Vitamina B3", "unidade": "mg"},
        "35": {"componente":"Vitamina B6", "unidade": "mg"},
        "36": {"componente":"Vitamina B12", "unidade": "mcg"},
        "37": {"componente":"Vitamina C", "unidade": "mg"},
        "38": {"componente":"Vitamina B9", "unidade": "mcg"},
    }

    //const vitaIDs = [[24, 25], 26, 27, 28, 29, 30, 31, 32, 33, 34];
    //const macroIDs = [1, 2, 3, 4];
    //const saisIDs = [5, 6, 7, 8, 9, 10, 11, 12, 13];
    //const vitaIDs = [14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

    const adPrint = (
        <>
            {
                props.ad ?
                    <TableRow style={{ borderBottom: '1px solid black' }}>
                        <TableCell colspan="6" align="center" style={{ borderBottom: '1px solid black' }}>
                            <img src={props.ad} class="mx-auto" />
                        </TableCell>
                    </TableRow>
                    :
                    ''
            }
        </>
    )

    const showTitle = (
        <>
            {
                props.showTitle
                ?
                    <TableRow style={{ borderTop: '3px solid black', borderBottom: '3px solid black' }}>
                        <TableCell colspan="6" align="center">
                            <Typography variant="h1" style={{ fontSize: '26px', fontWeight: 'bold' }}>
                                {props.info.name}
                            </Typography>
                        </TableCell>
                    </TableRow>
                :
                    ""
            }
        </>
    )

    return (
        <TableContainer ref={ref}>
            <Table size="small">
                <TableHead>

                    {showTitle}

                    <TableRow style={{ borderTop: '3px solid black', borderBottom: '3px solid black' }}>
                        <TableCell colspan="6" align="center">
                            <Typography variant="h2" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                INFORMAÇÃO NUTRICIONAL
                            </Typography>
                        </TableCell>
                    </TableRow>

                    {adPrint}


                </TableHead>
                <TableBody>

                    <TableRow style={{ borderTop: '3px solid black', borderBottom: '3px solid black' }}>
                        <TableCell align="center" colspan="3">
                            <Typography variant="h10" style={{ fontWeight: 'normal' }}>
                                CALORIAS TOTAIS ({compsName["5"].unidade}) por grama
                            </Typography>
                        </TableCell >
                        <TableCell colspan="3" id="nutri_1_value">
                            {props.data["5"]}
                        </TableCell>
                    </TableRow>

                    <TableRow style={{ borderBottom: '1px solid black' }}>
                        <TableCell colspan="2" align="center" style={{ border: 'none', fontWeight: 'bold', fontStyle: 'italic' }}>
                            Macronutrientes
                        </TableCell>
                        <TableCell colspan="2" align="center" style={{ border: 'none', fontWeight: 'bold', fontStyle: 'italic' }}>
                            Vitaminas
                        </TableCell>
                        <TableCell colspan="2" align="center" style={{ border: 'none', fontWeight: 'bold', fontStyle: 'italic' }}>
                            Sais minerais
                        </TableCell>
                    </TableRow>

                    {
                        mIDs.map(ids => (
                            <TableRow>
                                {
                                    ids.map((id, idx) => {
                                        return (idx == 1) ?
                                            (
                                                <>
                                                    <TableCell align="left" className={classes.borderLeft}>
                                                        {`${compsName[id].componente} - ${compsName[id].unidade}`}
                                                    </TableCell>
                                                    <TableCell id={'nutri_' + id + '_value'} align="right" className={classes.borderRight}>
                                                        {roundOne(props.data[id], 5)}
                                                    </TableCell>
                                                </>
                                            ) :
                                            (
                                                <>
                                                    <TableCell align="left" className={classes.borderDashed}>
                                                        {`${compsName[id].componente} - ${compsName[id].unidade}`}
                                                    </TableCell>
                                                    <TableCell id={'nutri_' + id + '_value'} align="right" className={classes.borderDashed}>
                                                        {roundOne(props.data[id], 5)}
                                                    </TableCell>
                                                </>
                                            )

                                    })
                                }
                            </TableRow>
                        ))
                    }

                    {
                        svIDs.map((ids, idx) => {
                            return (idx == 0) ?
                                (
                                    <TableRow>
                                        <TableCell colspan="2" rowspan="6" align="left" className={classes.borderTopRight}></TableCell>
                                        {
                                            ids.map((id, idx) => {
                                                return (idx == 0) ?
                                                    (
                                                        <>
                                                            <TableCell align="left" className={classes.borderLeft}>
                                                                {`${compsName[id].componente} - ${compsName[id].unidade}`}
                                                            </TableCell>
                                                            <TableCell id={'nutri_' + id + '_value'} align="right" className={classes.borderRight}>
                                                                {roundOne(props.data[id], 5)}
                                                            </TableCell>
                                                        </>
                                                    ) :
                                                    (
                                                        <>
                                                            <TableCell align="left" className={classes.borderDashed}>
                                                                {`${compsName[id].componente} - ${compsName[id].unidade}`}
                                                            </TableCell>
                                                            <TableCell id={'nutri_' + id + '_value'} align="right" className={classes.borderDashed}>
                                                                {roundOne(props.data[id], 5)}
                                                            </TableCell>
                                                        </>
                                                    )

                                            })
                                        }
                                    </TableRow>
                                ) :
                                (
                                    <TableRow>
                                        {
                                            ids.map((id, idx) => {
                                                return (idx == 0) ?
                                                    (
                                                        <>
                                                            <TableCell align="left" className={classes.borderLeft}>
                                                                {`${compsName[id].componente} - ${compsName[id].unidade}`}
                                                            </TableCell>
                                                            <TableCell id={'nutri_' + id + '_value'} align="right" className={classes.borderRight}>
                                                                {roundOne(props.data[id], 5)}
                                                            </TableCell>
                                                        </>
                                                    ) :
                                                    (
                                                        <>
                                                            <TableCell align="left" className={classes.borderDashed}>
                                                                {`${compsName[id].componente} - ${compsName[id].unidade}`}
                                                            </TableCell>
                                                            <TableCell id={'nutri_' + id + '_value'} align="right" className={classes.borderDashed}>
                                                                {roundOne(props.data[id], 5)}
                                                            </TableCell>
                                                        </>
                                                    )

                                            })
                                        }
                                    </TableRow>
                                )

                        })
                    }

                    <TableRow>
                        <TableCell align="left" className={classes.borderSolid}>
                        {`${compsName[38].componente} - ${compsName[38].unidade}`}
                        </TableCell>
                        <TableCell id={'nutri_34_value'} align="right" className={classes.borderSolid}>
                            {props.data[38]}
                        </TableCell>
                        <TableCell colspan="2" align="left" className={classes.borderTopLeft}></TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </TableContainer>
    );
})

export default NutriFacts;