import React, { forwardRef } from 'react';
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    borderTable: {
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
        borderBottom: '1px solid black'
    },
    borderTopBottom: {
        borderTop: '1px solid black',
        borderBottom: '1px solid black'
    },
    borderNone: {
        border: 'none'
    }
}))

const roundOne = (n, d) => Math.round(n * Math.pow(10, d)) / Math.pow(10, d)

const NutriFacts = forwardRef((props, ref) => {
    const classes = useStyles()

    // const macroIDs = [4, 5, 6, 7]
    // const saisIDs = [15, 16, 17, 18, 19, 20, 21, 22, 23]
    // //const vitaIDs = [25, 26, 27, 28, 29, 30, 31, 32, 33, 34];
    // //A, D, E, B1, B2, B3, B6, B12, C, B9
    // const vitaIDs = [        
    //     {
    //         "id": 25,
    //         "nome": "Vitamina A"
    //     },
    //     {
    //         "id": 26,
    //         "nome": "Vitamina D"
    //     },
    //     {
    //         "id": 27,
    //         "nome": "Vitamina E"
    //     },
    //     {
    //         "id": 28,
    //         "nome": "Vitamina B1"
    //     },
    //     {
    //         "id": 29,
    //         "nome": "Vitamina B2"
    //     },
    //     {
    //         "id": 30,
    //         "nome": "Vitamina B3"
    //     },
    //     {
    //         "id": 31,
    //         "nome": "Vitamina B6"
    //     },
    //     {
    //         "id": 32,
    //         "nome": "Vitamina B12"
    //     },
    //     {
    //         "id": 33,
    //         "nome": "Vitamina C"
    //     },
    //     {
    //         "id": 34,
    //         "nome": "Vitamina B9"
    //     }
    // ]

    const mIDs = [[4, 25, 15], [5, 26, 16], [6, 27, 17], [7, 28, 18]]
    const svIDs = [[29, 19], [30, 20], [31, 21], [32, 22], [33, 23]]

    const macroIDs = [8, 9, 10, 11]
    const saisIDs = [19, 20, 21, 22, 23, 24, 25, 26, 27]
    const vitaIDs = [29, 30, 31, 32, 33, 34, 35, 36, 37, 38];

    const compsName =         
    {
        "5": {"componente":"Energia", "unidade": "kcal"},
        "8": {"componente":"Carboidrato", "unidade": "g"},
        "9": {"componente":"Proteína", "unidade": "g"},
        "10": {"componente":"Lipídio", "unidade": "g"},
        "11": {"componente":"Fibra alimentar", "unidade": "g"},
        "19": {"componente":"Cálcio", "unidade": "mg"},
        "20": {"componente":"Ferro", "unidade": "mg"},
        "21": {"componente":"Sódio", "unidade": "mg"},
        "22": {"componente":"Magnésio", "unidade": "mg"},
        "23": {"componente":"Fósforo", "unidade": "mg"},
        "24": {"componente":"Potássio", "unidade": "mg"},
        "25": {"componente":"Zinco", "unidade": "mg"},
        "26": {"componente":"Cobre", "unidade": "mg"},
        "27": {"componente":"Selênio", "unidade": "mcg"},
        "29": {"componente":"Vitamina A", "unidade": "mcg"},
        "30": {"componente":"Vitamina D", "unidade": "mcg"},
        "31": {"componente":"Vitamina E", "unidade": "mg"},
        "32": {"componente":"Vitamina B1", "unidade": "mg"},
        "33": {"componente":"Vitamina B2", "unidade": "mg"},
        "34": {"componente":"Vitamina B3", "unidade": "mg"},
        "35": {"componente":"Vitamina B6", "unidade": "mg"},
        "36": {"componente":"Vitamina B12", "unidade": "mcg"},
        "37": {"componente":"Vitamina C", "unidade": "mg"},
        "38": {"componente":"Vitamina B9", "unidade": "mcg"},
    }

    //const vitaIDs = [[24, 25], 26, 27, 28, 29, 30, 31, 32, 33, 34];
    //const macroIDs = [1, 2, 3, 4];
    //const saisIDs = [5, 6, 7, 8, 9, 10, 11, 12, 13];
    //const vitaIDs = [14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

    const adPrint = (
        <>
            {
                props.ad
                ?
                    <TableRow style={{ borderBottom: '1px solid black' }}>
                        <TableCell colspan="6" align="center" style={{ borderBottom: '1px solid black' }}>
                            <img src={props.ad} class="mx-auto" />
                        </TableCell>
                    </TableRow>
                :
                    ""
            }
        </>
    )

    const showTitle = (
        <>
            {
                props.showTitle
                ?
                    <TableRow style={{ borderTop: '3px solid black', borderBottom: '3px solid black' }}>
                        <TableCell colspan="2" align="center">
                            <Typography variant="h1" style={{ fontSize: '26px', fontWeight: 'bold' }}>
                                {props.info.name}
                            </Typography>
                        </TableCell>
                    </TableRow>
                :
                    ""
            }
        </>
    )

    return (
        <TableContainer ref={ref}>
            <Table size="small">
                <TableHead>

                    {showTitle}

                    <TableRow style={{ borderTop: '3px solid black', borderBottom: '3px solid black' }}>
                        <TableCell colspan="2" align="center">
                            <Typography variant="h2" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                TABELA NUTRICIONAL
                            </Typography>
                        </TableCell>
                    </TableRow>

                    {adPrint}

                </TableHead>
                <TableBody>

                    <TableRow style={{ borderTop: '3px solid black', borderBottom: '3px solid black' }}>
                        <TableCell align="center" colspan="1">
                            <Typography variant="h2" style={{ fontSize: '16px', fontWeight: 'normal' }}>
                                CALORIAS TOTAIS - {compsName["5"].unidade}<br />por grama
                            </Typography>
                        </TableCell >
                        <TableCell colspan="1" id="nutri_1_value_xs">
                            {props.data["5"]}
                        </TableCell>
                    </TableRow>

                    <TableRow className={classes.borderTopBottom}>
                        <TableCell colspan="2" align="center" style={{ border: 'none', fontWeight: 'bold', fontStyle: 'italic' }}>
                            Macronutrientes
                        </TableCell>
                    </TableRow>
                    {
                        macroIDs.map(id => (
                            <TableRow style={{ borderBottom: '1px dashed black' }}>
                                <TableCell align="left" style={{ border: 'none' }}>
                                    {compsName[id].componente} - {compsName[id].unidade}
                                </TableCell>
                                <TableCell id={'nutri_' + id + '_value_xs'} align="right" style={{ border: 'none' }}>
                                    {roundOne(props.data[id], 5)}
                                </TableCell>
                            </TableRow>
                        ))
                    }

                    <TableRow className={classes.borderTopBottom}>
                        <TableCell colspan="2" align="center" style={{ border: 'none', fontWeight: 'bold', fontStyle: 'italic' }}>
                            Vitaminas
                        </TableCell>
                    </TableRow>
                    {
                        vitaIDs.map(id => (
                            <TableRow style={{ borderBottom: '1px dashed black' }}>
                                <TableCell align="left" style={{ border: 'none' }}>
                                    {compsName[id].componente} - {compsName[id].unidade}
                                </TableCell>
                                <TableCell id={'nutri_' + id + '_value_xs'} align="right" style={{ border: 'none' }}>
                                    {roundOne(props.data[id], 5)}
                                </TableCell>
                            </TableRow>
                        ))
                    }


                    <TableRow className={classes.borderTopBottom}>
                        <TableCell colspan="2" align="center" style={{ border: 'none', fontWeight: 'bold', fontStyle: 'italic' }}>
                            Sais minerais
                        </TableCell>
                    </TableRow>
                    {
                        saisIDs.map(id => (
                            <TableRow style={{ borderBottom: '1px dashed black' }}>
                                <TableCell align="left" style={{ border: 'none' }}>
                                    {compsName[id].componente} - {compsName[id].unidade}
                                </TableCell>
                                <TableCell id={'nutri_' + id + '_value_xs'} align="right" style={{ border: 'none' }}>
                                    {roundOne(props.data[id], 5)}
                                </TableCell>
                            </TableRow>
                        ))
                    }

                </TableBody>
            </Table>
        </TableContainer>
    );
})

export default NutriFacts;