/* eslint-disable no-use-before-define */
import React from 'react';

export default function Power({styles, data, fill}) {
  return (  
        <>
            <svg className={styles} {...data} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <g fill={fill}>
                    <path d="M7 2v11h3v9l7-12h-4l4-8z"></path>
                </g>
            </svg>
        </>
  );
}